/* ----------------------------------------------- */
/* !Notice Bar */
/* ----------------------------------------------- */

.noticebar > li > a {
    /* color: #547795; */
    font-size: 14px;
}

.noticebar > li > a .badge {
    padding: 4px 7px;
    font-size: 11px;
    border-radius: 100px;
}

.noticebar-empty {
    padding: 25px 35px !important;
    text-align: center;
}

.noticebar-empty-title {
    color: #E18E14;
}

.noticebar-empty-text {
    color: #777;
}

.noticebar-menu {
    width: 325px;
}

.noticebar-menu .nav-header {
    padding: 6px 12px;
    font-size: 13px;
    font-weight: 600;
}

.noticebar-menu .nav-header a {
    font-weight: 400;
}

.noticebar-menu > li {
    display: table;
    width: 100%;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px dotted #ccc;
}

.noticebar-menu > li:last-child {
    border-bottom: none;
}

.noticebar-menu > li > a {
    padding: 8px 12px;
    font-size: 12px;
    white-space: normal;
}

.noticebar-menu > li > a:hover {
    color: #333;
    background-color: transparent;
}

.noticebar-menu:before,
.noticebar-menu:after {
    display: none !important;
}

.noticebar-menu-view-all {
    text-align: center;
}

.noticebar-hoverable > li > a:hover {
    color: #333 !important;
    background-color: #edf0f4 !important;
}

.noticebar-item-image,
.noticebar-item-body {
    display: table-cell;
    vertical-align: middle;
}

.noticebar-item-image {
    width: 36px;
    font-size: 30px;
    text-align: center;
}

.noticebar-item-body {
    padding-left: 20px;
}

.noticebar-item-title {
    display: block;
}

.noticebar-item-time {
    display: block;
    color: #777;
}

@media (min-width: 768px) {
    .noticebar > li > a {
        height: 50px;
        font-size: 14px;
        padding: 14px 19px;
    }

    .noticebar > li > a .badge {
        position: absolute;
        top: 3px;
        right: 3px;
    }

    .badge-primary {
        background: #efa505;
    }

    .noticebar > .open > a:after {
        content: '';
        z-index: 1001;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        position: absolute;
        bottom: -4px;
        left: 50%;
        margin-left: -7px;
    }
}

@media (max-width: 767px) {
    .noticebar-menu {
        display: none !important;
    }
}

.noticebar .dropdown-menu>li>a:hover {
    background: #63a934 !important;
    color: #fff;
}

.navbar-nav>.open>a, .navbar-nav>.open>a:hover, .navbar-nav>.open>a:focus {
    background: #63a934;
    color: #fff;
}

.navbar-nav .dropdown-menu {
    border-top: 4px solid #63a934;
    padding: 5px 0px 5px;
}

.footer-bottom {
    position: fixed;
    bottom: 0px;
    left: 220px;
    z-index: 5;
    width: 100%;
    padding-right: 236px;
}


/* MORE link footer info Panels*/

a.more {

    display: block;
    padding: 8px 10px 8px 10px;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.88;
    filter: alpha(opacity=88);

}

a.more:hover {

    text-decoration: none;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    opacity: 1;
    filter: alpha(opacity=100);

}


.has-error {
    color: darkred;
}

.parsley-errors-list li {
    list-style: none;
    margin-left: -30px;
}

.contatto .popover-content {
    color: #666 !important;
    width: 240px !important;
}

/* FIX FIREFOX DROPDOWN TOGGLE (display:none) ***********************************/

.open .animated.inLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    display: block;
}
.show.animated.inLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    display: block;
}

.open .animated.flipY {
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    display: block;
}
.show.animated.flipY {
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    display: block;
}

.open .animated.inUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    display: block;
}
.show.animated.inUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    display: block;
}

/* DATATOOLS*/

.DTTT_container {
    text-align: right;
    padding: 5px 8px;
}

.DTTT_container:before, .DTTT_container:after {
    display: table;
    content: '';
}

.DTTT_container:after {
    clear: both;
}

.DTTT_container .left {
    float: left;
}

.DTTT_container .right {
    float: right;
}

.DTTT_container .left a {
    margin-right: 10px;
}

.DTTT_container .right a {
    margin-left: 10px;
}

.DTTT_container a {
    padding: 2px 6px;
    border: 1px solid transparent;
}

.DTTT_container a:hover {
    border-radius: 2px;
    background: #F7F7F7;
    border-color: #DADADA;
}

.DTTT_container a:hover:active {
    border-color: transparent;
    background: #e5e7eb;

}

.DTTT_container a i {
    margin-right: 5px;
    font-size: 10px;
}

.DTTT_container {
    display: inline-block;
    margin-left: 80px;
}

.DTTT_button embed {
    outline: none;
}

.DTTT_container a {
    position: relative;
}

.DTTT_print_info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 150px;
    margin-left: -200px;
    margin-top: -75px;
    text-align: center;
    color: #333;
    padding: 10px 30px;

    background: #ffffff; /* Old browsers */
    background: -webkit-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Chrome10+,Safari5.1+ */
    background:    -moz-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* FF3.6+ */
    background:     -ms-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* IE10+ */
    background:      -o-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Opera 11.10+ */
    background:         linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */

    opacity: 0.95;

    border: 1px solid black;
    border: 1px solid rgba(0, 0, 0, 0.5);

    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;

    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

.DTTT_print_info h6 {
    font-weight: normal;
    font-size: 28px;
    line-height: 28px;
    margin: 1em;
}

.DTTT_print_info p {
    font-size: 14px;
    line-height: 20px;
}

.dataTables_length .selectize-control {
    float: left;
    width: 65px;
    margin-right: 10px;
}

.dataTables_length label {
    line-height: 30px;
    margin: 0;
}

.dataTables_filter label, .dataTables_filter input {
    width: 100% !important;
}

#load-table {
    height: 400px;
    background: url(/assets/private/img/loading.gif) no-repeat center center;
}